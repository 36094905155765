import {tns} from "tiny-slider";

window.tns = tns;

// Media queries breakpoints
window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);

window.resizeTimer = null;
window.collectionsSliderobj = [];

function get_vw() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function get_vh() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.get_vh = get_vh;
window.get_vw = get_vw;

window.update_cart_info = function (){
    ajaxnav('', '#cart-info-wrp', 'content_types/cart/cart_info', false);
}

function ajaxnav(params) {
    // ajaxnav(url, container, template, update_address, append_history)
    if (typeof params !== 'object') {
        params = {
            url: arguments[0],
            container: arguments[1],
            template: arguments[2],
            update_address: arguments[3] !== undefined ? arguments[3] : true,
            append_history: arguments[4] !== undefined ? arguments[4] : true,
        }
    }

    // uzpidom default'iniais parametrais
    params = $.extend({
        update_address: true,
        append_history: true,
        method: 'GET',
        data: {},
        error: function (data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (params.callback !== undefined) {
                params.callback(data, textStatus, jqXHR);
            }
        },
        success: function (data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (typeof data == 'object') {
                if (data.reload === true) {
                    $('#ajax_loader').show();
                    window.location.href = data.url;
                } else {
                    ajaxnav($.extend({
                        container: params.container,
                        template: params.template,
                        update_address: params.update_address,
                        append_history: params.append_history,
                        callback: params.callback
                    }, data));
                }
            } else if (typeof data == 'string') {
                if (params.container !== undefined) {
                    let $data = $(data);
                    if (!$data.attr('id')) {
                        $data.attr('id', 'id-' + Math.random().toString(36).substr(2, 16));
                    }
                    let $previous = $(params.container).replaceWith($data);
                    init_components($data);
                    if (params.update_address) {
                        var pso = {
                            template: params.template,
                            container: params.container
                        }
                        var final_url = jqXHR.getResponseHeader('X-AJAXNAV-URL');
                        if (!final_url) final_url = params.url;
                        if (params.append_history) {
                            // window.history.replaceState(pso, '', window.location);
                            window.history.pushState(pso, '', final_url);
                        } else {
                            window.history.replaceState(pso, '', final_url);
                        }
                    }
                }
                if (params.callback !== undefined) {
                    params.callback(data, textStatus, jqXHR);
                }
            }
        }
    }, params);

    // vykdom ajax request'a
    $.ajax({
        url: params.url + (!params.url.match(/\?/) ? '?' : (!params.url.match(/&$/) ? '&' : '')) + 'display=' + params.template,
        method: params.method,
        data: params.data,
        success: params.success,
        beforeSend: function () {
            $('#ajax_loader').show();
        },
    });
}

window.ajaxnav = ajaxnav;

function init_selectpicker(context) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) && !$('.selectpicker', context).hasClass('non-mobile')) {
        $('.selectpicker', context).selectpicker('mobile');
    } else {
        $('.selectpicker', context).selectpicker();
    }
}

function init_components(context) {
    init_selectpicker(context);
}

window.init_components = init_components;

function init_ajaxnav() {
    // ajaxnav
    $(document).on('click', '[data-ajaxnav="true"]:not(form)', function (e) {
        var url = this.href !== undefined ? this.href : $(this).data('ajaxnav-url');
        var callback = $(this).data('ajaxnav-callback');
        if (url) {
            e.preventDefault();
            ajaxnav({
                url: url,
                container: $(this).data('ajaxnav-container'),
                template: $(this).data('ajaxnav-template'),
                callback: function () {
                    if (typeof window[callback] == 'function') {
                        window[callback]();
                    }
                }
            });
        }
    });

    $(document).on('submit', 'form[data-ajaxnav="true"]', function (e) {
        var url = this.action ? this.action : ($(this).data('ajaxnav-url') ? $(this).data('ajaxnav-url') : '');
        var callback = $(this).data('ajaxnav-callback');
        e.preventDefault();
        ajaxnav({
            url: url,
            method: this.method ? this.method.toUpperCase() : 'GET',
            data: $(this).serialize(),
            container: $(this).data('ajaxnav-container'),
            template: $(this).data('ajaxnav-template'),
            callback: function () {
                if (typeof window[callback] == 'function') {
                    window[callback]();
                }
            }
        });
        return false;
    });

    window.onpopstate = function (event) {
        if (event.state && event.state.container && event.state.template) {
            ajaxnav(window.location.href, event.state.container, event.state.template, false);
        } else {
            $('#ajax_loader').show();
            window.location.reload();
        }
    };

}

function update_wishlist_info() {
    $.ajax({
        url: '?display=content_types/wishlist/wishlist_info',
        method: 'post',
        success: function (html) {
            $('#wishlist-info').replaceWith(html);
        }
    });
}

function init_wishlist() {
    $(document).on('click', '.add-to-wishlist', function (e) {
        e.preventDefault();
        var el = $(this);
        if (el.data('id') == "") {
            alert($alert_message);
        } else {
            $.ajax({
                url: '?display=content_types/wishlist/update_wishlist',
                data: {element_id: el.data('id')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.status > 0) {
                        el.addClass('active');
                    } else if (json.status < 0) {
                        el.removeClass('active');
                    }
                    update_wishlist_info();
                }
            });
        }
    });
}

function init_tooltip(el, text) {
    const tooltip = new bootstrap.Tooltip(el, {
        title: text,
        trigger: 'manual'
    });

    tooltip.show()

    setTimeout(function () {
        tooltip.hide()
    }, 2000);
}

function update_comparison_info() {
    $.ajax({
        url: '?display=content_types/comparison/comparison_info',
        method: 'post',
        success: function (html) {
            $('#comparison-info-wrp').replaceWith(html);
        }
    });
}

function init_comparison() {
    $(document).on('click', '.add-to-comparison', function (e) {
        e.preventDefault();
        let el = $(this);
        if (el.data('id') == "") {
            alert($alert_message);
        } else {
            $.ajax({
                url: '?display=content_types/comparison/update_comparison',
                data: {element_id: el.data('id')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.status > 0) {
                        el.addClass('active');
                    } else if (json.status < 0) {
                        el.removeClass('active');
                    } else if (json.status === 0){
                        return init_tooltip(el, el.data('tooltip-notice'));
                    }

                    update_comparison_info();

                    if(el.hasClass('remove-comparison')){
                        ajaxnav('', '#comparison-listing', 'content_types/comparison/listing', false);
                    }
                }
            });
        }
    });
}

function init_page_editor_slider() {
    if (get_vw() < screen_sm) {
        $('.page_editor_columns_carousel').each(function () {
            if ($(this).find('.carousel-inner-x .item').length > 1) {
                var carousel_columns = tns({
                    container: $(this).find('.carousel-inner-x')[0],
                    items: 1,
                    autoplayButtonOutput: false,
                    autoplay: false,
                    loop: true,
                    nav: true,
                    controls: false,
                    autoplayTimeout: 5000,
                    autoHeight: true,
                    navPosition: 'bottom'
                });
            }
        });
    }
}

function init_categories_slider() {
    $('.categories_carousel').each(function() {
        if($(this).closest('#pages-detailed').length){
            var options = {
                container: $(this).find('.carousel-inner-x')[0],
                gutter: 20,
                autoplayButtonOutput: false,
                autoplay: false,
                loop: true,
                autoplayTimeout: 5000,
                navPosition: 'bottom',
                controlsContainer: $(this).find('.categories-controls')[0],
                responsive: {
                    0: {
                        items: 2,
                        nav: true,
                        controls: false,
                    },
                    576: {
                        items: 3,
                    },
                    768: {
                        items: 4,
                    },
                    992: {
                        items: 5,
                        nav: false,
                        controls: true,
                    },
                }
            };
        }else{
            var options = {
                container: $(this).find('.carousel-inner-x')[0],
                gutter: 20,
                autoplayButtonOutput: false,
                autoplay: false,
                loop: true,
                autoplayTimeout: 5000,
                navPosition: 'bottom',
                controlsContainer: $(this).find('.categories-controls')[0],
                responsive: {
                    0: {
                        items: 2,
                        nav: true,
                        controls: false,
                    },
                    576: {
                        items: 3,
                    },
                    768: {
                        items: 4,
                    },
                    992: {
                        items: 5,
                        nav: false,
                        controls: true,
                    },
                    1200: {
                        items: 6,
                    },
                }
            };
        }

        if ($(this).find('.carousel-inner-x .item').length > 1) {
            var carousel = tns(options);
        }
    });
}

function doModal(content, dialogClasses) {
    dialogClasses = typeof dialogClasses !== 'undefined' ? dialogClasses : 'modal-lg';
    let dialogCentered = get_vw() >= screen_sm ? 'modal-dialog-centered' : '';
    let html = '<div class="modal fade" id="dynamicModal" tabindex="-1" role="dialog">';
    html += '<div class="modal-dialog ' + dialogClasses + ' modal-fullscreen-sm-down modal-dialog-scrollable ' + dialogCentered + '" role="document">';
    html += '<div class="modal-content">';
    html += '<button class="close-btn" type="button" data-bs-dismiss="modal" aria-label="Close"><i class="icon-close"></i></button>';
    html += '<div class="modal-body">';
    html += content;
    html += '</div>'; 	// body
    html += '</div>';  	// content
    html += '</div>';  	// dialog
    html += '</div>';  	// modal
    $('body').append(html);
    $('#dynamicModal').modal();
    $('#dynamicModal').modal('show');
    $('#dynamicModal').on('hidden.bs.modal', function () {
        $(this).remove();
    });
}

function init_authorize() {
    $(document).on('click', '.need2login', function (e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/authorize',
            callback: function (html) {
                doModal(html, 'customers-auth');
            }
        });
    });
}

window.initCollectionSlider = function (container, standAlone) {
    standAlone = typeof standAlone !== 'undefined' ? standAlone : false;
    var singleCollSliderObj = {};
    let options = {
        autoplay: false,
        loop: false,
        gutter: 0,
        items: 4,
        autoplayButtonOutput: false,
        nav: false,
        controls: true,
        controlsContainer: $(container).closest('.collection').find('.collection-controls')[0],
    };

    if (standAlone) {
        if (get_vw() > screen_md && $(container).length && typeof singleCollSliderObj.isOn === 'undefined') {
            singleCollSliderObj = tns(Object.assign({}, options, {container: container}));
        } else if (get_vw() > screen_md && $(container).length && singleCollSliderObj.isOn === null) {
            singleCollSliderObj = singleCollSliderObj.rebuild();
        } else if (get_vw() < screen_md && singleCollSliderObj.isOn) {
            singleCollSliderObj.destroy();
        }
        return;
    }

    return tns(Object.assign({}, options, {container: container}));
}

window.initCollectionsSlider = function () {
    let vw = get_vw();
    let collectionItems = $('.collection-items');

    if (vw > screen_md && collectionItems.length && collectionsSliderobj.length === 0) {
        collectionItems.each(function () {
            collectionsSliderobj.push(initCollectionSlider(this));
        });
    } else if (vw > screen_md && collectionItems.length && collectionsSliderobj[0].isOn === null) {
        $.each(collectionsSliderobj, function (key, obj) {
            collectionsSliderobj[key] = obj.rebuild();
        });
    } else if (vw < screen_md && collectionsSliderobj.length) {
        $.each(collectionsSliderobj, function (key, obj) {
            obj.destroy();
        });
    }
}

window.initProductPhotosSlider = function () {
    if($('.product-photos').length){
        var productPhotos = tns({
            container: '.product-photos',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: false,
            mouseDrag: true,
            loop: false,
            controlsText: [
                '<i class="icon-angle-left"></i>',
                '<i class="icon-angle-right"></i>'
            ],
            navContainer: document.querySelector('.product-photos-nav'),
            responsive: {
                0: {
                    controls: true,
                    nav: false,
                },
                992: {
                    controls: false,
                    nav: true,
                }
            }
        });

        productPhotos.events.on('indexChanged', function (info) {
            $('.photos-counter .current').text(info.displayIndex);
        });

        $('[data-fancybox="product"]').fancybox({
            beforeClose : function(instance) {
                productPhotos.goTo(instance.currIndex);
            }
        });
    }
}

function toggle_company(){
    if ( $('#company:checked').val() == 1) {
        $('.company-wrp').slideDown('fast');
        $('.company-wrp').find('input').removeAttr("disabled");
    }else{
        $('.company-wrp').find('input').attr("disabled", "disabled");
        $('.company-wrp').slideUp('fast');
    }
}

function showPartialVisible(){
    let showBtn = $('.js-show-partial-visible');
    if(!showBtn.length) return;
    let descBox = $('.partial-desc-box');
    let descBoxH = descBox.height();
    let partialEl = $(showBtn.data('partial'));
    let partialElH = partialEl.outerHeight();

    descBox.removeAttr('style');

    if(partialElH > descBoxH){
        showBtn.show();
    }else{
        showBtn.hide();
    }
}

function megaMenuDesktopLeftPos(){
    if($('#mega-menu-desktop').hasClass('active')){
        let offset = $('.mega-menu-button').offset();
        $('#mega-menu-desktop').css('left', offset.left + 'px');
    }
}

function init_article_sliders_activity() {
    if (get_vw() > screen_sm_min && $('.editor_type_article_slider').length) {
        $('.editor_type_article_slider').each(function () {
            let parent_el = $(this);
            $(this).find('.item').on('mouseenter', function () {
                let img = $(this).data('img');
                let banner_column = parent_el.find('.banner_column');

                parent_el.find('.item').removeClass('active');
                banner_column.css('background-image', 'url("' + img + '")');
                $(this).addClass('active');
            });
        });
    }
}

$(function () {
    init_components(document);
    init_ajaxnav();
    init_authorize();
    init_wishlist();
    init_comparison();
    initCollectionsSlider();
    initProductPhotosSlider();
    showPartialVisible();
    init_page_editor_slider();
    init_categories_slider();
    init_article_sliders_activity()

    $(window).on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            initCollectionsSlider();
            showPartialVisible();
            megaMenuDesktopLeftPos();
        }, 250);
    });

    //new/updated

    if (typeof $pages_news_count !== 'undefined') {
        var $page_news = 2;
        const newsBtnWrp = $('#news-list-page .btn-wrp');

        if (parseInt($page_news) > parseInt($pages_news_count)) {
            newsBtnWrp.hide();
        }

        $('#news-list-page .btn-wrp').click(function (e) {
            e.preventDefault();
            $('#ajax_loader').show();
            newsBtnWrp.hide();

            $.get('?display='+ $(this).data('template') +'&page=' + $page_news, function (data) {
                $page_news++;
                $('#news-list-page .news-list').append($(data).find('.news-list').html());
                $('#ajax_loader').hide();

                if (parseInt($page_news) <= parseInt($pages_news_count)) {
                    newsBtnWrp.show();
                }
            });
        });
    }

    if($('#linepromos').length){
        tns({
            container: '.linepromos-carousel',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: true,
            loop: true,
            nav: false,
            controls: true,
            autoplayTimeout: 5000,
            speed: 1000,
            controlsText: [
                '<i class="icon-angle-left"></i>',
                '<i class="icon-angle-right"></i>'
            ],
        });
    }

    $('.js-toggle-search').click(function (e) {
        e.preventDefault();
        let quickSearch = $('#quick-search');

        quickSearch.toggleClass('active');

        if (quickSearch.hasClass('active')) {
            quickSearch.find('input[name="search"]').val('');

            quickSearch.find('#search-suggestions-results').empty();

            setTimeout(function () {
                quickSearch.find('input[name="search"]').focus();
            }, 100);
        }
    });

    var search_delay = null;
    $("#quick-search-form input[name='search']").keyup(function () {
        let val = $(this).val();
        if (val.length > 2) {
            if (search_delay != null) clearTimeout(search_delay);
            search_delay = setTimeout(function () {
                $("#search-suggestions-results").html(loader);
                $('#quick-search').addClass('active');

                let search_request = $.ajax({
                    type: 'POST',
                    url: '?display=content_types/search/suggestions.tpl',
                    data: {q: val},
                    success: function (data) {
                        $("#search-suggestions-results").html(data);
                    }
                });
            }, 1000);
        } else {
            if (val.length > 0) {
                $("#search-suggestions-results").html(moreChars);
                $('#quick-search').addClass('active');
            } else {
                $('#quick-search').removeClass('active');
            }
        }
    });

    $('html body').click(function (e) {
        if (!$(e.target).closest('#quick-search').length && !$(e.target).is('#quick-search') && get_vw() > screen_md) {
            $('#quick-search').removeClass('active');
        }
    });

    if ($('#hero-slider-desktop').length) {
        var heroSliderDesktop = tns({
            container: '#hero-slider-desktop',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: true,
            mouseDrag: true,
            nav: false,
            controls: false,
            autoplayTimeout: 10000,
            speed: 500,
            onInit: function (info) {
                $('.hero-slider-desktop .hero-nav-counter .total').text(info.slideCount);
                setTimeout(function () {
                    $('.hero-slider-desktop .hero-nav-timer').addClass('time-init');
                }, 500);
            }
        });

        heroSliderDesktop.events.on('indexChanged', function (info) {
            $('.hero-slider-desktop .hero-nav .current').text(info.displayIndex);
        });

        heroSliderDesktop.events.on('transitionStart', function () {
            $('.hero-slider-desktop .hero-nav-timer').removeClass('time-init');
        });

        heroSliderDesktop.events.on('transitionEnd', function () {
            $('.hero-slider-desktop .hero-nav-timer').addClass('time-init');
        });

        $('.hero-slider-desktop .hero-arrow-btn.prev').click(function (e) {
            e.preventDefault();
            heroSliderDesktop.pause();
            heroSliderDesktop.goTo('prev');
            heroSliderDesktop.play();
        });

        $('.hero-slider-desktop .hero-arrow-btn.next').click(function (e) {
            e.preventDefault();
            heroSliderDesktop.pause();
            heroSliderDesktop.goTo('next');
            heroSliderDesktop.play();
        });
    }

    if ($('#hero-slider-mobile').length) {
        var heroSliderMobile = tns({
            container: '#hero-slider-mobile',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: true,
            mouseDrag: true,
            nav: false,
            controls: false,
            autoplayTimeout: 10000,
            speed: 500,
            onInit: function (info) {
                $('.hero-slider-mobile .hero-nav-counter .total').text(info.slideCount);
                setTimeout(function () {
                    $('.hero-slider-mobile .hero-nav-timer').addClass('time-init');
                }, 500);
            }
        });

        heroSliderMobile.events.on('indexChanged', function (info) {
            $('.hero-slider-mobile .hero-nav .current').text(info.displayIndex);
        });

        heroSliderMobile.events.on('transitionStart', function () {
            $('.hero-slider-mobile .hero-nav-timer').removeClass('time-init');
        });

        heroSliderMobile.events.on('transitionEnd', function () {
            $('.hero-slider-mobile .hero-nav-timer').addClass('time-init');
        });

        $('.hero-slider-mobile .hero-arrow-btn.prev').click(function (e) {
            e.preventDefault();
            heroSliderMobile.pause();
            heroSliderMobile.goTo('prev');
            heroSliderMobile.play();
        });

        $('.hero-slider-mobile .hero-arrow-btn.next').click(function (e) {
            e.preventDefault();
            heroSliderMobile.pause();
            heroSliderMobile.goTo('next');
            heroSliderMobile.play();
        });
    }

    $('.js-toggle-dropdown').click(function (e) {
        e.preventDefault();

        $(this).closest('.dropdown').find('> .submenu').toggleClass('active');
    });

    $('.js-toggle-mega-menu').click(function (e) {
        e.preventDefault();

        $('.mega-menu').toggleClass('active');
        $('.submenu').removeClass('active');
    });

    $('.mega-menu-button').click(function (e) {
        e.preventDefault();
        let offset = $(this).offset();
        $(this).toggleClass('active');
        $('#mega-menu-desktop').css('left', offset.left + 'px').toggleClass('active');
    });

    $('#mega-menu-desktop').click(function (e) {
        e.stopPropagation();
    });

    $(document).click(function(e) {
        if (!$(e.target).is('#mega-menu-desktop') && !$(e.target).is('.mega-menu-button')) {
            if ($('#mega-menu-desktop').is(':visible')) {
                $('#mega-menu-desktop').removeClass('active');
                $('.mega-menu-button').removeClass('active');
            }
        }
    });

    $('[data-submenu-target]').hover(function () {
        let target = $(this).data('submenu-target');
        let parent = $(this).closest('#mega-menu-desktop');
        parent.find('.submenu-inner').addClass('d-none');
        parent.find('[data-submenu-target]').removeClass('active');
        $(target).removeClass('d-none');
        $(this).addClass('active');
    });

    $(document).on('click', '.js-toggle-sorting', function (e) {
        e.preventDefault();
        $('#products-listing-sorting').toggleClass('active');
    });

    $(document).on('click', '.js-show-partial-visible', function (e) {
        e.preventDefault();
        let partialEl = $($(this).data('partial'));
        let partialElH = partialEl.outerHeight();
        let moreText = $(this).data('more-text');
        let lessText = $(this).data('less-text');

        if($(this).hasClass('more')){
            $(this).removeClass('more');
            $(this).text(moreText);
            partialEl.parent().removeAttr('style');
        }else{
            $(this).addClass('more');
            $(this).text(lessText);
            partialEl.parent().css('max-height', partialElH + 'px');
        }
    });

    $(document).on('click', '.js-show-specs', function (e) {
        e.preventDefault();
        let moreText = $(this).data('more-text');
        let lessText = $(this).data('less-text');

        if($(this).hasClass('more')){
            $(this).removeClass('more');
            $(this).text(moreText);
            $('.specs table').removeClass('show');
        }else{
            $(this).addClass('more');
            $(this).text(lessText);
            $('.specs table').addClass('show');
        }
    });

    $(document).on('click', '.filter-limit-btn', function (e) {
        e.preventDefault();
        let parent = $(this).closest('.filter-group');
        let list = parent.find('ul');
        let moreText = $(this).data('more-text');
        let lessText = $(this).data('less-text');

        if($(this).hasClass('more')){
            $(this).removeClass('more');
            $(this).text(moreText);
            list.addClass('limit-list-display');
        }else{
            $(this).addClass('more');
            $(this).text(lessText);
            list.removeClass('limit-list-display');
        }
    });

    $(document).on('change', '.recommended-products .product-small-element', function () {
        let currencySymbol = $('.recommended-products').data('currency-symbol');
        let price = 0;
        let oldPrice = 0;

        $(this).toggleClass('active');

        $('.recommended-products .product-small-element.active').each(function () {
            price += parseFloat($(this).data('price'));
            oldPrice += parseFloat($(this).data('old-price'));
        });

        price = price ? price.toFixed(2).replace('.', ',') : 0;

        $('.recommended-summary .current-price').text(price + ' ' + currencySymbol);
        $('.recommended-summary .button').attr('disabled', price === 0);

        if(oldPrice){
            $('.recommended-summary .price').addClass('discount');
            $('.recommended-summary .old-price').text(oldPrice.toFixed(2).replace('.', ',') + ' ' + currencySymbol);
        }else{
            $('.recommended-summary .price').removeClass('discount');
        }
    });

    $('.recommended-products').submit(function(e) {
        e.preventDefault();
        let data = $(this).serialize();

        ajaxnav({
            url: document.location.href,
            data: data,
            method: 'POST',
            template: 'content_types/products/add2cart_recommended',
            callback: function() {
                ajaxnav('', '#cart-info-wrp', 'content_types/cart/cart_info&&add2cart=1&' + data, false);
            }
        });
    });

    $(document).on('click', '.js-chargers-modal', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/products/chargers_modal',
            callback: function (html) {
                doModal(html, 'chargers-modal');
            }
        });
    });

    $(document).on('change', '#cart-items select', function() {
        $(this).closest('form').submit();
    });

    $(document).on('click', '.show-products-wrp .show-btn', function(e) {
        e.preventDefault();

        if ($(this).attr('aria-expanded') === 'true') {
            $(this).text($(this).data('hide-title'));
        } else {
            $(this).text($(this).data('show-title'));
        }
    });

    $("#company").click(toggle_company);
    toggle_company();

    $(document).on('click', '.warranty', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/products/warranty',
            callback: function (html) {
                doModal(html, 'html-content');
            }
        });
    });

    $(document).on('click', '#payment-type-options .payment-item', function() {
        $('#payment-type-options input[name="payment_type"]').prop('checked', false);
    });

    $(document).on('click', '#payment-type-options [data-payment-name]', function() {
        $("#orders-pay button[type='submit']").attr('data-dl-payment', $(this).data('payment-name'));
    });

    $(document).on('change', '.coupon-block input[name="coupon_no_greetings"]', function () {
        let block = $('.coupon-block .greetings-form');

        if ($(this).is(':checked')) {
            block.hide();
            block.find('input, textarea').attr('disabled', true);
        } else {
            block.show();
            block.find('input, textarea').removeAttr('disabled');
        }
    });

    $(document).on('mouseenter', '.product-element', function () {
        $(this).css('min-height', $(this).outerHeight(true) + 'px');
        $(this).addClass('hover');
    });

    $(document).on('mouseleave', '.product-element', function () {
        $(this).css('min-height', 'auto');
        $(this).removeClass('hover');
    });

    if (get_vw() >= screen_md) {
        $(document).on('click', '.add-to-cart-listing', function(e){
            e.preventDefault();
            var el = $(this);
            let data = { state: 'add2cart', item_id: el.data('id'), amount: 1 };

            ajaxnav({
                url: el.data('url'),
                data: data,
                method: 'POST',
                template: 'content_types/products/add2cart',
                callback: function() {
                    ajaxnav('', '#cart-info-wrp', 'content_types/cart/cart_info&item_id='+ el.data('id') +'&add2cart=1', false);
                }
            });
        });
    }

    $(document).on('click', "[data-dl-info]:not(.add_to_wishlist)", function(e) {
        var info = $(this).data('dl-info');
        var event = $(this).data('dl-event');
        var crm_id = $(this).data('dl-crm-id');

        if (event == 'begin_checkout') {
            dl_begin_checkout(info, crm_id);
        } else if (event == 'add_shipping_info') {
            let shipping_method = $(this).data('dl_shipping');

            if (shipping_method) {
                info.shipping_tier = shipping_method;
                dl_add_shipping_info(info, crm_id);
            }
        } else if (event == 'add_payment_info') {
            let payment_method = $(this).data('dl-payment');

            if (payment_method) {
                info.payment_type = payment_method;
                dl_add_payment_info(info, crm_id);
            }
        } else {
            dl_select_item(info, crm_id);
        }
    });
});

// datalayer select_item
function dl_select_item(productObj, crm_id) {
    let obj = {
        event: "select_item",
        ecommerce: {
            items: [                                           // required
                productObj
            ]
        }
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}

function dl_begin_checkout(ecommerceObj, crm_id) {
    let obj = {
        event: "begin_checkout",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}

function dl_add_shipping_info(ecommerceObj, crm_id) {
    let obj = {
        event: "add_shipping_info",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);

}

function dl_add_payment_info(ecommerceObj, crm_id) {
    let obj = {
        event: "add_payment_info",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}